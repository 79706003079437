import React from 'react';
import image from '../media/fireworks.png';


export default function Home() {
    return (
        <main className="bg-blue-300">
            {/*
            <img src={image} alt="Beach" className="absolute object-cover w-full h-full"/>
            */}
            <section className="relative flex justify-center min-h-screen pt-12 lg:pt-64 px-8">
                <h1 className="text-6xl text-blue-100 font-bold leading-none lg:leading-snug home-name">Guanting. </h1>
                <h3 className="mt-10 text-6xl text-blue-500 font-bold leading-none lg:leading-snug ">Engineer. Innovator. Lifelong Learner.</h3>
                
            </section>
            <section className="flex justify-center">
                <img src={image} alt="Beach" className=""/>
                <p className="text-blue-500">Site under active construction (07/21/2022). More to be added soon!</p>
            </section>
        </main>
    )
}
/** 
 * What we use to communicate to Sanity. 
 * npm install @sanity/cli
 * npm install @sanity/client
 * */

import sanityClient from '@sanity/client'

export default sanityClient({
    projectId: "j5wqtol3", 
    /* can find projectId in 2 spots! studio/sanity.json, and also manage.sanity.io */
    dataset: "production"
})